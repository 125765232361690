// 正式
let url
if (window.location.host == 'cc1.ccpscw.com') {
  url = 'https://file.ccpscw.com/' // 潮玩
} else if (window.location.host == 'www.cardhub.org.cn') {
  url = 'https://pictureoss.cardhub.org.cn/' // 卡牌
} else if(window.location.host == 'chain.zhzlkj.net'){
  url = 'https://fchain.zhzlkj.net/' // 交易中心
}else if(window.location.host == 'os.cnfxcloud.com:51443'){
  url = 'https://d6t.cnfxcloud.com:51443/' // 文化大数据测试
}else if(window.location.host == 'os.fxiyun.com:51443'){
  url = 'https://d6t1.fxiyun.com:51443/' // 文化大数据测试
}else if(window.location.host == 'mgta1.fxiyun.com:51443'){
  url = 'https://d6ta1.fxiyun.com:51443/' // 文化大数据正式
}else if(window.location.host == 'demo.fxiyun.com:51443'){
  url = 'https://d6ta1.fxiyun.com:51443/' // 文化大数据正式
}else if(window.location.host == 'www.dxc.pro'){
  url = 'https://files.dxc.pro/' // 电子商会
}else if(window.location.host == 'www.sdocenter.cn'){
  url = 'https://file.sdocenter.cn/' // 舞台剧
}else if(window.location.host == 'backup.sdocenter.cn'){
  url = 'https://file.sdocenter.cn/' // 舞台剧
}else if(window.location.host == 'test-www.sdocenter.cn'){
  url = 'http://test-download.sdocenter.cn/' // 舞台剧测试
}else if(window.location.host == 'bql1.xiutang.xyz'){
  url = 'https://bql4.xiutang.xyz/' // 数字商品重庆节点
}else if(window.location.host == 'www.shanganwenhua.com'){
  url = 'https://file.shanganwenhua.com/' // 深圳舞台剧
}else{
  url = 'https://files.wespace.cn/'
}

export default {
  login_url: "https://login.service.wespace.cn/",
  node_url: "https://node.wespace.cn/",
  cr_url: "https://cr.service.wespace.cn/",
  coprt_tyr: 'https://cr.service.wespace.cn/',
  box_url: "https://box.wespace.cn/",
  res_url: "https://re.service.wespace.cn/",
  asset_url: "https://as.service.wespace.cn/",
  trAsset_url: 'https://tra.service.wespace.cn/',
  file_url: url,
  // file_url: 'https://filecard.cardhub.org.cn/',
  // file_url: 'https://file.ccpscw.com/',
  upload_url: "https://up.service.wespace.cn/",
  certificate_url: 'https://aut.service.wespace.cn/',
  contract_url: "https://con.service.wespace.cn/",
  newCr_url: "https://crreg.service.wespace.cn/cr/reg/",

  Real_url: "https://artwork.wespace.cn/",
  Real_url1: "https://meeting.licenseinfo.cn/",
  Book_url: "https://tra.service.wespace.cn/",
  token_url: 'https://token.wespace.cn',
  shop_url: 'https://order.service.wespace.cn',
  asset_tourl: 'https://order.service.wespace.cn/',
  asset_public: 'https://lawsuit.wespace.cn/',
  moreCr_url: "https://crreg.service.wespace.cn/",
  cms_url: "https://cms.wespace.cn/",
  assets_url: 'https://wpt.licenseinfo.cn/',
  newAssets_url: 'https://enjoymusic.wespace.cn/assets',
  newAssets_url2: 'https://api.wespace.cn/assets', //newAssets_url 和 newAssets_url2等同
  newAssets_url3: 'https://api.wespace.cn/enjoymusic-user',
  wptWespce_url: 'https://wpt.wespace.cn',
  activity_url: 'https://meeting.wespace.cn',
  pay_url: 'https://pay.wespace.cn',
  verify_url: 'https://verify.licenseinfo.cn',
  qrCode_url: 'https://assetscode.licenseinfo.cn',
  file_upload: 'https://api.wespace.cn/file-upload',//新的上传-待改为正确的域名
  cookety_url: 'https://cr.service.wespace.cn',
  verifynew_url: 'https://verify.wespace.cn'
}
