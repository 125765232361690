import request from "@/utils/request";  //导入封装请求的js文件

// 首页获取图文信息列表
export function getNoticeListApi (query) {
  return request({
    url: '/goodsApi/notice/list/v2',
    method: 'post',
    data: query,
  })
}
// 公告详情
export function getNoticeDetails (query) {
  return request({
    url: '/goodsApi/notice/details',
    method: 'post',
    data: query,
  })
}
// 推荐列表
export function getRecommendList (query) {
  return request({
    url: '/goodsApi/notice/recommend/list',
    method: 'post',
    data: query,
  })
}
//提交上架申请
export function submitUpShelf (query) {
  return request({
    url: '/assetsApi/pr/goods/goods/up/shelf/v3',
    method: 'post',
    data: query,
  })
}
//提交下架申请
export function submitOffShelf (query) {
  return request({
    url: '/assetsApi/pr/goods/goods/off/shelf/v3',
    method: 'post',
    data: query,
  })
}
//提交下架申请
export function getMenusList (query) {
  return request({
    url: '/userApi/menus/navigation/list',
    method: 'post',
    data: query,
  })
}

//首发
export function postVerlist (query) {
  return request({
    url: '/goodsApi/goods/ver/list/v3',
    method: 'post',
    data: query,
  })
}
//二手交易
export function postGoodslist (query) {
  return request({
    url: '/assetsApi/second/hand/goods/list',
    method: 'get',
    params: query,
  })
}
//二手交易详情
export function getDetails (query) {
  return request({
    url: '/assetsApi/second/hand/goods/details',
    method: 'get',
    params: query,
  })
}
//分类选择
export function getGoodsheat (params) {
  return request({
    url: '/assetsApi/pr/basic/type/list/v2',
    method: 'get',
    params: params,
  })
}
//详情
export function postVdetalis (query) {
  return request({
    url: '/goodsApi/goods/ver/details/v4',
    method: 'post',
    data: query,
  })
}
//推荐
export function postVlist (query) {
  return request({
    url: '/goodsApi/goods/index/list',
    method: 'post',
    data: query,
  })
}

//获取首页公告
export function getNotice (query) {
  return request({
    url: '/goodsApi/notice/list',
    method: 'post',
    data: query,
  })
}
//获取产品信息
export function getGoodsVer (query) {
  return request({
    url: '/goodsApi/goods/ver/goodsVer/details',
    method: 'post',
    data: query,
  })
}
//获取视频类型
export function getMediaCategory (query) {
  return request({
    url: '/goodsApi/goods/ver/details/category',
    method: 'post',
    data: query,
  })
}
//提交产品信息
export function updateGoodsVer (query) {
  return request({
    url: '/goodsApi/goods/ver/goodsVer/update',
    method: 'post',
    data: query,
  })
}
// 获取装修信息
export function getFooterInfo (query) {
  return request({
    url: '/goodsApi/pageDecoration/details/getOne',
    method: 'get',
    params: query,
  })
}
// 获取用户收款方式数据
export function payPlatform (query) {
  return request({
    url: '/payApi/pay/user/platform/list/v2',
    method: 'post',
    data: query,
  })
}
// 订单列表
export function getOrderList (query) {
  return request({
    url: '/orderApi/order/list ',
    method: 'post',
    data: query,
  })
}
// 订单详情
export function getOrderDetail (query) {
  return request({
    url: '/orderApi/order/detail',
    method: 'post',
    data: query,
  })
}
// 取消订单
export function cancelOrder (query) {
  return request({
    url: '/orderApi/order/cancel',
    method: 'post',
    data: query,
  })
}
// 提交订单
export function unifiedOrderOrder (query) {
  return request({
    url: '/orderApi/order/unifiedOrder',
    method: 'post',
    data: query,
  })
}
// 企业PC支付
export function transferPay (query) {
  return request({
    url: '/payApi/umb/pay/transferPay',
    method: 'post',
    data: query,
  })
}
// 民生-个人开户
export function umbUserRegister (data) {
  return request({
    url: '/payApi/umb/pay/user/payment/register',
    method: 'post',
    data: data,
  })
}
// 汇付开户
export function companyRegister (data) {
  return request({
    url: '/payApi/huifu/pay/companyRegister/v2',
    method: 'post',
    data: data,
  })
}
// 汇付省市区json
export function getHuifuArea (data) {
  return request({
    url: '/payApi/huifu/pay/area/code/list',
    method: 'post',
    data: data,
  })
}
// 民生支付开户
export function umbCompanyRegister (data) {
  return request({
    url: '/payApi/umb/pay/companyRegister',
    method: 'post',
    data: data,
  })
}
// 开户详情
export function getApplyDetail (data) {
  return request({
    url: '/payApi/pay/company/register/apply/detail',
    method: 'post',
    data: data,
  })
}
// 汇付支持银行列表json
export function getBankList (data) {
  return request({
    url: '/payApi/huifu/pay/bank/code/list',
    method: 'post',
    data: data,
  })
}
// 获取收款方式
export function getModeList (data) {
  return request({
    url: '/payApi/pay/user/payment/mode/list',
    method: 'post',
    data: data,
  })
}
// 快捷支付获取验证码
export function getPayQuickCode (data) {
  return request({
    url: '/payApi/umb/pay/quick/getVerificationCode',
    method: 'post',
    data: data,
  })
}
// 快捷支付
export function payQuick (data) {
  return request({
    url: '/payApi/umb/pay/quick/pay',
    method: 'post',
    data: data,
  })
}
